<template>
    <div>
        <h1 class="giant-header">404</h1>
        <p>The page you were looking for does not exist :(</p>
    </div>
</template>

<script>
    export default {
        name: 'FourOhFour',
    }
</script>

<style lang="css" scoped>
.giant-header {
    font-size: 10em;
    margin: 0;
    padding: 0;
    font-weight: 900;
}
</style>