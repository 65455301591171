<template>
    <div class="divider">

    </div>
</template>

<script>
    export default {
        name: 'DashboardSectionDivider',
    }
</script>

<style lang="css" scoped>
.divider {
    width: 100%;
    height: 5px;
    background: #292929;
    margin-top: 10px;
}

</style>